/* This entire file is taken from the mobile app code to re-use the same encryption logic for generating encrypted file */

import AES from "crypto-js/aes";

const KEY = "8cc44c04f1b3fd0ce243a84e709027e0";

function encrypt(value: any): any {
    return AES.encrypt(value, KEY);
}

export function encryptEntry(objectToEncrypt: any, arrayToEncrypt = [] as any, keyNameForArray = ""): any {
    let encryptedObject = {} as any;

    for (const key in objectToEncrypt) {
        if (key !== keyNameForArray) {
            console.log(key);
            if (key === "tabContentImages" || key === "tabImages" || key === "resources" || key === "resourceLinks") {
                console.log("encrypting" + key + " array");
                const encryptyedValue = encrypt(JSON.stringify(objectToEncrypt[key])).toString();
                encryptedObject = { ...encryptedObject, [encrypt(key).toString()]: encryptyedValue };
            } else if (key !== "id" && key !== "journeySetId" && key !== "quadrantId" && key !== "js89sd8lsDKf3" && key !== 'locale') {
                if (objectToEncrypt[key]) {
                    const encryptyedValue = encrypt(objectToEncrypt[key].toString()).toString();
                    encryptedObject = { ...encryptedObject, [encrypt(key).toString()]: encryptyedValue };
                }
            } else {
                encryptedObject = { ...encryptedObject, [key]: objectToEncrypt[key] };
            }
        }
    }

    if (arrayToEncrypt.length > 0) {
        let encryptedArray = [] as any;
        for (const currentTab of arrayToEncrypt) {
            let encryptedObjectForArray = {};
            for (const key in currentTab) {
                if (currentTab[key]) {
                    const encryptyedValue = encrypt(currentTab[key].toString()).toString();
                    encryptedObjectForArray = { ...encryptedObjectForArray, [encrypt(key).toString()]: encryptyedValue };
                }
            }
            encryptedArray = [encryptedObjectForArray, ...encryptedArray];
        }
        encryptedObject = { ...encryptedObject, [keyNameForArray]: encryptedArray };
    }
    return encryptedObject;
}