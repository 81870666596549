
import {computed, defineComponent, ref} from "vue";
import {
    IonContent,
    IonPage,
    IonLabel,
    IonItem,
    IonIcon,
    alertController,
    IonModal,
    IonInput, 
    IonReorderGroup,
    IonButtons,
    IonButton,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonReorder,
    IonText
} from "@ionic/vue";
import {
    ellipsisVertical,
    cloudDownloadOutline,
    pushOutline,
    closeOutline
} from 'ionicons/icons';
import {JOURNEY_SET_TYPES} from "@/models/JourneySetTypes";
import {execute} from "@/mixins/LoadingMixin";
import {
  getJourneySets,
  duplicateJourneySet as duplicate,
  deleteJourneySet,
  publish as publishJourneySet,
  download as downloadJourneySet,
  unpublish,
  updateJourneySetOrder,
  updateJourneySet, updateJourneySetDefaults,
} from '@/services/JourneySetService';
import {getMissingInfo, JourneySet} from "@/models/JourneySetModel";
import FixedFooter from "@/components/FixedFooter.vue";
import router from "@/router";
import {presentNativeAlert, presentValidationAlert} from "@/services/Notify";
import {useStore} from "vuex";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import {ActionSheetBuilder} from "@/mixins/ActionSheetBuilder";
import { getTranslatedNumber } from "@/utils/NumberTranslate";
import {downloadFile, downloadFileFromResponse} from "@/utils/DownloadUtil";
import { formatDate } from '@/utils/DatesUtil';
import {encryptEntry} from "@/utils/EncryptUtil";
import {openSelectModal} from '@/services/SelectModalService';
import {Settings} from 'luxon';
import {SelectOption} from '@/models/SelectOptionModel';
import {getTemplate} from "@/services/TemplateService";

export default defineComponent({
    name: "JourneySets",
    components: {
        IonContent,
        IonPage,
        IonIcon,
        IonModal,
        IonLabel,
        IonItem,
        IonInput,
        IonReorderGroup,
        IonReorder,
        IonButtons,
        IonButton,
        IonTitle,
        IonToolbar,
        IonHeader,
        FixedFooter,
        IonText
    },
    props: {
        typeId: {
            type: String,
            required: true
        }
    },
    async ionViewWillEnter() {
        this.journeySetsLoaded = false;
        await this.store.dispatch('app/setPageTitle', JOURNEY_SET_TYPES.get(this.typeId));
        await execute(async () => {
            this.journeySets = await getJourneySets(this.typeId, this.store.getters['app/selectedAppInstanceId'], this.viewPublishedOnly);
            this.journeySetsLoaded = true;
        }, this.language.errors.gettingJourneySets);
    },
    setup(props) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const journeySetsLoaded = ref(false);
        const journeySets = ref([] as JourneySet[]);
        const journeySetToDuplicate = ref({} as JourneySet);
        const duplicateJourneySetTitle = ref('');
        const isDuplicateModalOpen = ref(false);
        const setOpen = (state: boolean) => isDuplicateModalOpen.value = state;
        const viewPublishedOnly = computed(() => router.currentRoute.value.name === 'publishedJourneySets');
        const reorderEvent = ref({} as CustomEvent);
        const reorderDisabled = ref(true);
        
        function getDefaults(journeySet: JourneySet) {
            let defaults = '';
            if (journeySet.isPwaDefault) {
                defaults += language.value.pwa + language.value.listSeperator;
            }
            if (journeySet.isSecureDefault) {
                defaults += language.value.secure + language.value.listSeperator;
            }
            if (journeySet.isAppDefault) {
                defaults += language.value.nonSecure;
            }
            
            return defaults.replace(new RegExp(language.value.listSeperator + '$'), '');
        }

        
        const goToJourneySetJourneys = (journeySetId: string) => {
            if (!viewPublishedOnly.value) {
                router.push({name: 'journeySetJourneys', params: {journeySetId: journeySetId}});
            }
        }

        const duplicateJourneySet = async () => {
            if (duplicateJourneySetTitle.value.trim() !== '') {
                journeySetToDuplicate.value.title = duplicateJourneySetTitle.value;
                await execute(async () => {
                    await duplicate(journeySetToDuplicate.value);
                    journeySets.value = await getJourneySets(props.typeId, store.getters['app/selectedAppInstanceId'], viewPublishedOnly.value);
                    setOpen(false);
                }, language.value.errors.duplicatingJourneySet);
            } else {
                presentValidationAlert(language.value.validation.enterName);
            }
        }
        
        const getEncryptedFileFromResponse = async ({ blob }: any) => {
            const fileContent = await blob.text();
            const journeySet = JSON.parse(fileContent);
            const encryptedJourneysAndTabs = [] as any[];
            journeySet.journeys.forEach((journey: any) => {
                // const encryptedTabs = [] as any[];
                // journey.tabs.forEach((tab: any) => {
                //     encryptedTabs.push(encryptEntry(tab, tab.resources, "resources"));
                // });
                encryptedJourneysAndTabs.push(encryptEntry(journey, journey.tabs, "tabs"));
            });
            const encryptedJourneySet = encryptEntry(journeySet, [], "journeys");
            encryptedJourneySet.journeys = encryptedJourneysAndTabs;
            const encryptedBlob = new Blob([JSON.stringify(encryptedJourneySet)], {type : 'text/plain'});
            downloadFile(encryptedBlob, `${journeySet.pdfName}-$D3@-${journeySet.id}.txt`);
        }
        
        const publish = async (journeySetId: string) => {
            await execute(async () => {
                await publishJourneySet(journeySetId);
                journeySets.value.forEach((journeySet: JourneySet) => {
                    if (journeySet.id === journeySetId) {
                        journeySet.published = true;
                        journeySet.updateAvailable = false;
                        return;
                    }
                })
            }, language.value.errors.publishing);
        }
        
        const download = async (journeySetId: string) => {
            await execute(async () => {
                const response = await downloadJourneySet(journeySetId);
                await getEncryptedFileFromResponse(response);
            }, language.value.errors.download);
        }
        
        const publishOrDownload = async (shouldDownload: boolean, journeySetId: string) => {
            if (shouldDownload) {
                await download(journeySetId);
            } else {
                await publish(journeySetId);
            }
        } 
        
        const confirmGenerateJourneySetFile = (journeySet: JourneySet, message: string, shouldDownload: boolean) => {
            presentNativeAlert({
                cssClass: 'custom-alert',
                header: language.value.missingInfo,
                message: message,
                buttons: [{
                    text: language.value.cancel,
                    role: 'cancel',
                    handler: () => {
                        alertController.dismiss();
                    }}, {
                    text: shouldDownload ? language.value.download : language.value.publish,
                    handler: async () => {
                        await publishOrDownload(shouldDownload, journeySet.id);
                    }},
                ]
            });
        }

        const confirmPublish = (journeySet: JourneySet) => {
            presentNativeAlert({
                cssClass: 'custom-alert',
                header: language.value.publishJourneySetQuestion,
                message: language.value.verifyPublishJourneySetQuestion,
                buttons: [{
                    text: language.value.cancel,
                    role: 'cancel',
                    handler: () => {
                        alertController.dismiss();
                    }}, {
                    text: language.value.publish,
                    handler: async () => {
                        await publishOrDownload(false, journeySet.id);
                    }},
                ]
            });
        }
        
        const generateJourneySetFile = async (journeySet: JourneySet, shouldDownload: boolean) => {
            const message = journeySet.legacy ? "" : await getMissingInfo(journeySet.id, shouldDownload, language.value);
            if (message){
                confirmGenerateJourneySetFile(journeySet, message, shouldDownload);
            } else if (shouldDownload) {
                await publishOrDownload(shouldDownload, journeySet.id);
            } else {
                await confirmPublish(journeySet);
            }
        }
      
        const isDefault = (journeySet: JourneySet): boolean => {
          return journeySet.isPwaDefault || journeySet.isAppDefault || journeySet.isSecureDefault;
        }

        const templateInfoLabel = (journeySet: JourneySet): string => {
            const templateLabel = new String().concat(" - " , journeySet.templateName ," (", journeySet.enableCustomContent ? language.value.customContentLabel : language.value.basicContentLabel , ")") ;
            return templateLabel ;
        }

        const confirmUnpublish = (journeySet: JourneySet) => {
            const options = {
              cssClass: 'custom-alert',
              header: language.value.unpublishJourneySetQuestion,
              message: isDefault(journeySet) ? language.value.unpublishDefaultJourneySet : language.value.verifyUnpublishJourneySetQuestion,
              buttons: [{
                text: language.value.cancel,
                role: 'cancel',
                handler: () => {
                  alertController.dismiss();
                }}]
            };
            
            if(!isDefault(journeySet)) {
              options.buttons.push({
                text: language.value.unpublish,
                role: '',
                handler: async () => {
                  await execute(async () => {
                    await unpublish(journeySet.id);
                    await router.push({name: 'publishedJourneySetGroups'});
                  }, language.value.errors.unpublishing);
                }
              });
            }
            presentNativeAlert(options);
        }
        
        const deleteJS= async (journeySet: JourneySet) => {
            presentNativeAlert({
                cssClass: 'custom-alert',
                header: language.value.deleteJourneySetQuestion,
                message: language.value.verifyDeleteJourneySetQuestion,
                buttons: [{
                    text: language.value.cancel,
                    role: 'cancel',
                    handler: () => {
                        alertController.dismiss();
                    }}, {
                    text: language.value.delete,
                    handler: async () => {
                        await alertController.dismiss();
                        await execute(async () => {
                            await deleteJourneySet(journeySet.id);
                            journeySets.value = await getJourneySets(props.typeId, store.getters['app/selectedAppInstanceId'], viewPublishedOnly.value);
                        }, language.value.errors.deletingJourneySet);
                    }},
                ]
            });
        }
        
        const selectDefaults = async (journeySet: JourneySet) => {
          const defaultSelectOptions = [{
            value: "pwa",
            text: language.value.pwa,
            isChecked: journeySet.isPwaDefault
          },{
            value: "secure",
            text: language.value.secure,
            isChecked: journeySet.isSecureDefault
          },{
            value: "app",
            text: language.value.nonSecure,
            isChecked: journeySet.isAppDefault
          }];
  
          const defaultSelect = await openSelectModal(language.value.editDefaultJourneySet,'checkbox',defaultSelectOptions,false);
          defaultSelect.onWillDismiss().then(async ({ data }) => {
            data.forEach((datum: SelectOption) => {
              switch (datum.value) {
                case "pwa":
                  journeySet.isPwaDefault = datum.isChecked;
                  break;
                case "secure":
                  journeySet.isSecureDefault = datum.isChecked;
                  break;
                case "app":
                  journeySet.isAppDefault = datum.isChecked;
                  break;
                default:
                  break;
              }
            });
            
            await updateJourneySetDefaults(journeySet);
          });          
        };
        
        const presentRegularOptions = async (journeySet: JourneySet) => {
          const actionSheetBuilder = new ActionSheetBuilder(journeySet.title)
              .addEdit(() => goToJourneySetJourneys(journeySet.id))
              .addDuplicate(() => {
                journeySetToDuplicate.value = Object.assign(journeySetToDuplicate.value, journeySet);
                setOpen(true);
              })
              .addDownload(true, () => generateJourneySetFile(journeySet, true))
              .addPublish(() => generateJourneySetFile(journeySet, false))
          if (!journeySet.published) {
            actionSheetBuilder.addDelete(async () => await deleteJS(journeySet));
          } 
          const actionSheet = await actionSheetBuilder.create();
          await actionSheet.present();
        }
        
        const presentPublishOptions = async (journeySet: JourneySet) => {
            const actionSheetBuilder = new ActionSheetBuilder(journeySet.title)
                .addDefault(() => { selectDefaults(journeySet)})
                .addDownload(true,() => generateJourneySetFile(journeySet, true))
                .addUnpublish(() => confirmUnpublish(journeySet));
            const actionSheet = await actionSheetBuilder.create();
            await actionSheet.present();
        }

        const presentOptions = (journeySet: JourneySet) => {
           viewPublishedOnly.value ? presentPublishOptions(journeySet) :  presentRegularOptions(journeySet);
        }

        const getTranslatedNumberText = (length: number) => {
            return getTranslatedNumber(length || 0) + ' ' + (length === 1 ? language.value.journey : language.value.journeys);
        }
        
        const cancelReorder = () => {
            reorderDisabled.value = true;
            journeySets.value = journeySets.value.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        }

        const handleReorder = (event: CustomEvent) => {
            reorderEvent.value = event;
            journeySets.value = reorderEvent.value.detail.complete(journeySets.value);
        }
        
        const commitReorder = async () => {
            //minOrder is needed here because the 'displayOrder' of these journey sets may not start at 1.
            //That is because some journey sets are shipped out by default with each app, and these are additional.
            //The ordering of these need to fit in with the order specified in the default sets
            const minOrder = journeySets.value.reduce((min, journeySet) => journeySet.displayOrder < min ? journeySet.displayOrder : min, journeySets.value[0].displayOrder);
            for (let i = 0; i < journeySets.value.length; i++) {
                journeySets.value[i].displayOrder = minOrder + i;
            }
            await execute(async () => {
                await updateJourneySetOrder(journeySets.value);
                reorderDisabled.value = true;
            }, language.value.errors.reorderingJourneySets);
            journeySets.value = await getJourneySets(props.typeId, store.getters['app/selectedAppInstanceId'], viewPublishedOnly.value);
        }
        
        return {
            language,
            journeySets,
            goToJourneySetJourneys,
            ellipsisVertical,
            cloudDownloadOutline,
            pushOutline,
            footerButtons: computed(() => 
                    new FooterButtonsBuilder()
                .addClearButton(false, () => { router.push({ name: 'journeySetInstructions'}); }, language.value.addNewJourneySet)
                .create()
            ),
            presentOptions,
            duplicateJourneySetTitle,
            duplicateJourneySet,
            closeOutline,
            isDuplicateModalOpen,
            setOpen,
            journeySetsLoaded,
            store,
            getTranslatedNumber,
            viewPublishedOnly,
            getTranslatedNumberText,
            formatDate,
            reorderDisabled,
            handleReorder,
            commitReorder,
            cancelReorder,
            getDefaults,
            isDefault,
            templateInfoLabel
        }
    }
});
